if (process.browser) {
  Object.assign(__webpack_share_scopes__.default, {
    "next/link": {
      [next.version]: {
        loaded: true,
        get: () => Promise.resolve(() => require("next/link")),
      },
    },
    "next/router": {
      [next.version]: {
        loaded: true,
        get: () => Promise.resolve(() => require("next/router")),
      },
    },
    "next/head": {
      [next.version]: {
        loaded: true,
        get: () => Promise.resolve(() => require("next/head")),
      },
    },
    "next/dynamic": {
      [next.version]: {
        loaded: true,
        get: () => Promise.resolve(() => require("next/dynamic")),
      },
    },
  });
  
  if(next.version.startsWith("12")) {
    Object.assign(__webpack_share_scopes__.default, {
     "next/script": {
        [next.version]: {
          loaded: true,
          get: () => Promise.resolve(() => require("next/script")),
        },
      },
    });
  }
}
if (process.browser) {
window.employee?.init(__webpack_share_scopes__.default);
window.auth?.init(__webpack_share_scopes__.default);
window.license?.init(__webpack_share_scopes__.default);
window.card?.init(__webpack_share_scopes__.default);
window.recharge?.init(__webpack_share_scopes__.default);
window.admin?.init(__webpack_share_scopes__.default);
window.report?.init(__webpack_share_scopes__.default);
window.ifood_card?.init(__webpack_share_scopes__.default);
window.company?.init(__webpack_share_scopes__.default);
window.dashboard?.init(__webpack_share_scopes__.default);
window.financial?.init(__webpack_share_scopes__.default);
window.notification?.init(__webpack_share_scopes__.default);
window.ticket?.init(__webpack_share_scopes__.default);
window.marketplace?.init(__webpack_share_scopes__.default);
window.collective_agreement?.init(__webpack_share_scopes__.default);
};import '@ifood/pomodoro-fonts';
import '@ifood/design-language-icons/assets/ifdl-icons.css';

import '../styles/globals.css';

import { isProduction } from '@benefits/common-utils';
import { variables } from '@benefits/environment';
import { sw } from '@benefits/service-worker';
import { gtmInitialize, hotjarInitialize, initializeEventDispatcher } from '@benefits/tracking';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Script from 'next/script';
import { ReactElement, ReactNode, useEffect } from 'react';
import { Userpilot } from 'userpilot';

import { AllProviders } from '../components/AllProviders';

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const App: React.FC<AppPropsWithLayout> = ({ Component, pageProps, router }) => {
  const isProd = isProduction();

  useEffect(() => {
    initializeEventDispatcher();
    gtmInitialize();

    Userpilot.initialize('NX-36ce9f82');
    hotjarInitialize();

    const storeServiceWorker = async () => {
      if (isProd) await sw.registerServiceWorker();
    };

    storeServiceWorker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <AllProviders pathname={router.pathname}>
      <Script
        src="https://repo.incognia.com/web/1.2.1/incognia-web-sdk.js"
        onReady={() => window.IncogniaWebSdk.init(variables.INCOGNIA_APP_ID)}
      />
      {getLayout(<Component {...pageProps} />)}
    </AllProviders>
  );
};

export default App;
