import { variables } from '@benefits/environment';

export const API = '/login';
export const API_PORTAL = '/portal';
export const BFF_URL = variables.BFF_URL;
export const BASE_URL_PORTAL = BFF_URL + API_PORTAL;
export const BASE_URL = BFF_URL + API;

export const endpoints = {
  v1: {
    permissions: {
      get: '/v1/permissions',
    },
    recoveryPassword: {
      post: '/v1/password-recovery',
      get: '/v1/password-recovery',
    },
    switchGroup: {
      get: '/v1/switch-group',
    },
  },
  v2: {
    setup: {
      get: '/v2/setup',
    },
  },
};
