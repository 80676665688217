import { UserContext } from '@benefits/common-services';
import { isServer } from '@benefits/common-utils';
import { ErrorEventType, ErrorEvents, eventDispatcher } from '@benefits/tracking';
import { useContext, useMemo } from 'react';

export const useErrorEventDispatcher = () => {
  const context = useContext(UserContext);
  const user = context?.state?.user;
  const dispatcher = isServer?.() ? undefined : eventDispatcher?.events?.error;

  const SessionData = useMemo(
    () => ({
      UserID: user?.userId,
      GroupID: user?.group?.id,
    }),
    [user]
  );

  const sendEvent = (eventName: ErrorEventType, data?: any) => {
    if (dispatcher) {
      const eventFunction = dispatcher[ErrorEvents[eventName]];

      if (eventFunction) {
        eventFunction({
          ...data,
          ...SessionData,
        });
      }
    }
  };

  return {
    sendEvent,
  };
};
