import { UserContext } from '@benefits/common-services';
import { isServer } from '@benefits/common-utils';
import { useMessage } from '@benefits/message';
import { AllEvents, EventType, allEventsDispatcher, dataLayer, parseFeatureName } from '@benefits/tracking';
import { PropsWithChildren, useContext } from 'react';

export type EventReceive = {
  eventName: EventType;
  data?: any;
  colabRange: string;
};

export const EventsListener: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatcherAllEvents = isServer() ? undefined : allEventsDispatcher();

  useMessage('trackingEvents', {
    onReceive: (message) => {
      const event = message as EventReceive;

      if (event?.eventName) {
        sendEvent(event.eventName, event.colabRange, event.data);
      }
    },
  });

  const sendEvent = (eventName: EventType, colabRange: string, data?: any) => {
    if (dispatcherAllEvents) {
      const eventKey = AllEvents[eventName];
      const entity = parseFeatureName(eventKey);
      const event = `platform_b2b_${entity}`;

      const dataLayerEvent = {
        event,
        event_key: eventKey,
        colab_range_real: colabRange,
        ...data,
      };

      if (entity && dispatcherAllEvents[entity]) {
        const eventFunction = dispatcherAllEvents[entity].events[entity][eventKey];

        if (eventFunction) {
          eventFunction({
            ...data,
            colab_range_real: colabRange,
          });
          dataLayer(dataLayerEvent);
        }
      }
    }
  };

  return <>{children}</>;
};
